import * as React from "react"
import SimpleReactLightbox from "simple-react-lightbox"

import Footer from "./Footer"
import "../css/style.css"
import "bootstrap/dist/css/bootstrap.min.css"

const Layout = ({ children }) => {
  return (
    <SimpleReactLightbox>
      <div className="d-flex flex-column min-vh-100">
        {children}
        <Footer />
      </div>
    </SimpleReactLightbox>
  )
}

export default Layout
