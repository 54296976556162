import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import logo from "../assets/images/logo.png"

const Footer = () => {
  const query = graphql`
    {
      strapiContactPage {
        address
        timings
      }
    }
  `

  const { strapiContactPage: info } = useStaticQuery(query)

  return (
    <footer className="p-3">
      <Container>
        <div className="d-flex align-items-center justify-content-evenly text-center flex-column flex-lg-row">
          <img src={logo} alt="logo" className="logo" height="90" width="90" />
          <p className="my-2 my-lg-0">{info.address}</p>
          <p className="timings">{info.timings}</p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
